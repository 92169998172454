<template>
  <el-dialog
    class="changePasswordDialog"
    title=""
    :visible.sync="dialogVisible.show"
    width="1100px"
    :show-close="false"
    :before-close="handleClose">
    <div class="content">
      <div class="left">
        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/new-saas/PC/home/passwordDialog-left1.png" alt="">
      </div>
      <div class="right">
        <p class="title">欢迎加入伟东尖椒企业大学！</p>
        <p class="tip">为了方便您的使用，请完成密码设置。如未进行密码设置，后续将无法使用密码登录功能。</p>
        <el-form :model="ruleForm" status-icon label-position="top" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="登录密码" prop="pass">
            <el-input :type="password1" placeholder="请输入8至20位登录密码" v-model="ruleForm.password" autocomplete="off">
              <template slot="append">
                <span @click="handleshowPsd(1)" class="pwd-icon">
                  <svg-icon icon-class="yanjing-2" v-if="password1 == 'text'"></svg-icon>
                  <svg-icon v-else icon-class="yanjing-1"></svg-icon>
                </span>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="checkPass">
            <el-input :type="password2" placeholder="请再次输入8至20位登录密码" v-model="ruleForm.checkPass" autocomplete="off">
              <template slot="append">
                <span @click="handleshowPsd(2)" class="pwd-icon">
                  <svg-icon icon-class="yanjing-2" v-if="password2 == 'text'"></svg-icon>
                  <svg-icon v-else icon-class="yanjing-1"></svg-icon>
                </span>
              </template>
            </el-input>
            <p class="passTip">可自行前往个人中心-账号安全进行密码设置</p>
          </el-form-item>
          <el-checkbox class="checkbox" v-model="checked">不再提示</el-checkbox>
          <el-form-item class="button-item">
            <el-button type="primary" @click="submitForm('ruleForm')">确认</el-button>
            <el-button type="info" @click="handleClose('ruleForm')">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props:{
    dialogVisible:{
      type:Object,
      default:() => {
        return {
          show:false
        }
      }
    }
  },
  data(){
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return{
      password1:'password',
      password2:'password',
      checked:false,
      ruleForm:{
        password:'',
        checkPass:''
      },
      rules: {
          pass: [
            { validator: validatePass, trigger: 'blur' }
          ],
          checkPass: [
            { validator: validatePass2, trigger: 'blur' }
          ],
        }
    }
  },
  methods:{
    handleshowPsd(type){
      if(type == 1){
        this.password1 = this.password1 === 'text' ? 'password' : 'text'
      }else{
        this.password2 = this.password2 === 'text' ? 'password' : 'text'
      }
    },
    initPassword(){
      const params = {
        password:this.ruleForm.password
      }
      this.$api.usercenter.initPassword({params}).then(res => {
        if(res.data){
          this.$message.success('密码设置成功');
          this.dialogVisible.show = false;
        }
      })
    },
    async submitForm(formName) {
      if(this.checked && (!this.ruleForm.password || !this.ruleForm.checkPass)){
        await this.$api.usercenter.updatePasswordTime({})
        this.dialogVisible.show = false;
        sessionStorage.getItem('isSettingPasswordHomeDialog',true)
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.initPassword()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    async handleClose(formName){
      if(this.checked){
        await this.$api.usercenter.updatePasswordTime({})
      }
      sessionStorage.setItem('isSettingPasswordHomeDialog',true)
      this.$refs[formName].resetFields();
      this.dialogVisible.show = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.changePasswordDialog{
  ::v-deep .el-dialog{
    background-image: url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/new-saas/PC/home/passwordDialog-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 16px;
    .el-dialog__body{
      padding: 50px 55px 70px;  
      .content{
        display: flex;
        align-items: center;
        .left{
          margin-right: 70px;
          img{
            width: 478px;
          }  
        }  
      }
      .right{
        width: 312px; 
        .el-form-item{
          margin-bottom: 13px;
        }
        .checkbox{
          margin-top: 4px;  
          margin-bottom: 8px;
          .el-checkbox__inner{
            border-color: #A5B4CB;
            border-radius: 2px;
          }
          .el-checkbox__label{
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 17px;
            padding-left: 6px;
          }
        }
        .el-form-item__label{
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #141D4F;
          line-height: 20px;  
          padding-bottom: 4px;
        }
        .el-form-item__content{
          line-height: 30px; 
          
        }
        .button-item{
          .el-form-item__content{
            width: 100%;
            display: flex;
          }
          .el-button{
            padding: 12px 50px;  
            border-radius: 8px;
            span{
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
            }
          } 
          .el-button--primary{
            background-color: $WDPrimaryColor;
            border-color: $WDPrimaryColor;
          }
          .el-button--info{
            background-color: #F6F7F9;
            border-color: #F6F7F9;
            span{
              color: #666666;
            }
          }
          .el-button+.el-button{
            margin-left: 52px;
          }
        }
        .el-input-group__append{
          padding: 0;  
          padding-right: 8px;
          background-color:transparent;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          border-color: #A5B4CB;
          .icon{
            font-size: 25px;  
          }
        }
        .el-input__inner{
          border-right: 0;  
          // width: 312px;
          height: 30px;
          padding-right:0;
          border-top-left-radius:8px;
          border-bottom-left-radius: 8px;
          border-color: #A5B4CB;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #7996BB;
        }
        .el-input__suffix{
          display: none;  
        }
        .title{
          font-size: 24px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #141D4F;
          line-height: 33px;  
          margin-bottom: 4px;
        } 
        .tip{
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 17px;  
          margin-bottom: 22px;
        }
        .passTip{
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: $WDPrimaryColor;
          line-height: 17px;
          margin-top: 5px;
        }
      }
    }
  }
}

</style>